import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'

import bakery from '../images/logos/arc_bakery.png'
import carrefour from '../images/logos/carrefour.png'
import catinvest from '../images/logos/catinvest.png'
import mallcraiova from '../images/logos/mallcraiova.png'
import melimelo from '../images/logos/meli_melo.jpg'
import mhs from '../images/logos/mhs.png'
import pain from '../images/logos/pain_plaizir.png'
import zentiva from '../images/logos/zentiva.png'
import tom from '../images/logos/tom.png'
import cora from '../images/logos/cora.png'
import esplanada from '../images/logos/esplanada.png'
import palace from '../images/logos/palace.png'


const LogosWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap:3rem;
    grid-row-gap: 1rem;
    justify-items:center;
    align-items: center;
    padding: 2rem 1rem;
    
    /*mobile view */
    @media(max-width: 750px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-row-gap: .5rem;
        padding: 0;
        margin: 1.7rem 0;

    }
`

const ClientsWrapper = styled.div`

    display: grid;
    grid-template-rows: auto auto;
    text-align: center;
    
    /*mobile view */
    @media(max-width: 750px){
        padding-top: 2rem;
    }
`

const StyledImg = styled.img`
    width: 100px;

    /*mobile view */
    @media(max-width: 750px){
        width: 100px;

    }

`

const AboutGroup = styled.div`
   display: grid;
  border-radius: 3px;
  position: relative;
  width: 70wv;
  margin: 1rem auto;
  box-shadow: 0 20px 40px ${({ theme }) => theme.text};
  /*desktop version*/
  @media (min-width: 750px) {
    margin-top: 1rem;
    max-width: 76%;
    place-items: center;
  }

  h2 {
    padding-top: .5rem;
    margin: 0 auto;
    color: ${({ theme }) => theme.text};
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.1;
    text-decoration: underline;
    text-decoration-color: #d6ead7;
    /* mobile version */
    @media (max-width: 750px) {
      font-size: 1.5rem;
   }
  }
   .text {
    font-size: .8rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 5px;
    text-align: left;
    margin-left: 1rem;
    width: 65vw;
    /* mobile version */
    @media (max-width: 750px) {
      width: 86vw;
      font-size: .7rem;
   }
  }

  
`

export default function AboutPage() {
   return (
      <div>
         <Layout>
            <AboutGroup>
               <h2>Despre</h2>
               <br />
               <p className="text">ACERA CONSTRUCT este o companie de Construcții și Management de Proiect și Costuri,
               ce opereaza la nivel național.<br /><br />Echipa noastră, formată din Architecți, Ingineri și Manageri de Proiect, are o
               experiență profesională considerabilă, dobândită prin ani de lucru cu companii locale și internaționale de construcții și management de proiect.
               <br />
                  <br />
               Ne concentram pe valoarea adaugata a serviciilor noastre, o consecinta naturala a multi ani de experienta in licitatii, executie, cost control si management de proiect.
               </p>

               <ClientsWrapper>
                  <h2>Clienți</h2>
                  <LogosWrapper>
                     <StyledImg src={mallcraiova} alt="mallcraiova" />
                     <StyledImg src={catinvest} alt="catinvest" />
                     <StyledImg src={zentiva} alt="zentiva" />
                     <StyledImg src={mhs} alt="mhs" />
                     <StyledImg src={bakery} alt="bakery" />
                     <StyledImg src={carrefour} alt="bakery" />
                     <StyledImg src={pain} alt="pain" />
                     <StyledImg src={tom} alt="tom" />
                     <StyledImg src={cora} alt="cora" />
                     <StyledImg src={palace} alt="palace" />
                     <StyledImg src={esplanada} alt="esplanada" />
                     <StyledImg src={melimelo} alt="melimelo" />
                  </LogosWrapper>
               </ClientsWrapper>
            </AboutGroup>
         </Layout>
      </div>
   )
}
